import { DEFAULT_PROTOCOL_CLIENT } from 'constants/ApiConstant';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { DesktopAppContext } from 'utils/socket';

function DesktopAppProvider({ children, token }) {
  useEffect(() => {
    if (token) {
      const timeout = setTimeout(() => {
        window.location.href = 'https://do.goavana.com';
      }, 3000);

      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = `${DEFAULT_PROTOCOL_CLIENT}/auth?token=${token}`;
      document.body.appendChild(iframe);

      window.addEventListener('blur', () => {
        clearTimeout(timeout);
        document.body.removeChild(iframe);
      });

      return () => {
        clearTimeout(timeout);
        if (document.body.contains(iframe)) {
          document.body.removeChild(iframe);
        }
      };
    }
  }, [token]);

  return (
    <DesktopAppContext.Provider value={{ url: DEFAULT_PROTOCOL_CLIENT }}>
      {children}
    </DesktopAppContext.Provider>
  );
}

const mapStateToProps = ({ auth }) => {
  const { token } = auth;
  return { token };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DesktopAppProvider);
