import {
  CREATE_TASK_SUCCESS,
  DELETE_CURRENT_TASK_SUCCESS,
  DELETE_TASK_SUCCESS,
  DRAG_TASK_SUCCESS,
  LOAD_TASK_BY_LIST_SUCCESS,
  SET_LABELS_SUCCESS,
  SET_MEMBERS_SUCCESS,
  SET_DESCRIPTION_SUCCESS,
  TASK_MESSAGE,
  UPDATE_TASK_SUCCESS,
  SET_STORYPOINT_SUCCESS,
} from "redux/constants/manage-project/task.type";

const initialState = {
  data: {},
  loading: false,
  message: "",
  allTask: [],
};

function TaskReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_TASK_BY_LIST_SUCCESS:
      if (action.payload?.length > 0) {
        for (let i = 0; i < action.payload.length; i++) {
          const idx = state.allTask.findIndex(
            (t) => t._id === action.payload[i]._id
          );
          if (idx === -1) {
            state = {
              ...state,
              allTask: [...state.allTask, action.payload[i]],
            };
          } else if (state.allTask[idx].status !== action.payload[i].status) {
            state.allTask[idx] = action.payload[i];
          }
        }
        state.data = {
          ...state.data,
          [action.payload[0].listId]: action.payload,
        };
      }
      return state;
    case DELETE_TASK_SUCCESS:
      state.allTask = state.allTask.filter(task=>task._id !== action.payload._id)
      state.data = {
        ...state.data,
        [action.payload.listId]: state.data[action.payload.listId].filter(
          (task) => task._id !== action.payload._id
        ),
      };
      return state;
    case CREATE_TASK_SUCCESS:
      const existTask = state.allTask.find(
        (t) => t._id === action.payload.task._id
      );
      if (!existTask) {
        state = { ...state, allTask: [...state.allTask, action.payload.task] };
      }
      const tmpData =
        state.data[action.payload.idList] &&
        state.data[action.payload.idList].length > 0
          ? state.data[action.payload.idList]
          : [];
      const exist = tmpData.find(
        (data) => data._id === action.payload.task._id
      );
      if (!exist) tmpData.push(action.payload.task);

      state.data = {
        ...state.data,
        [action.payload.idList]: tmpData,
      };
      return state;
    case UPDATE_TASK_SUCCESS:
      state.data[action.payload.listId] = state.data[action.payload.listId].map(
        (t) => (t._id === action.payload._id ? action.payload : t)
      );
      return state;

    case SET_MEMBERS_SUCCESS:
      state.data[action.payload.listId] = state.data[action.payload.listId].map(
        (t) => (t._id === action.payload._id ? action.payload : t)
      );
      return state;

    case SET_LABELS_SUCCESS:
      state.data[action.payload.listId] = state.data[action.payload.listId].map(
        (t) => (t._id === action.payload._id ? action.payload : t)
      );
      return state;

    case SET_DESCRIPTION_SUCCESS:
      state.data[action.payload.listId] = state.data[action.payload.listId].map(
        (t) => (t._id === action.payload._id ? action.payload : t)
      );
      return state;

    case SET_STORYPOINT_SUCCESS:
      state.data[action.payload.listId] = state.data[action.payload.listId].map(
        (t) => (t._id === action.payload._id ? action.payload : t)
      );
      return state;

    case DRAG_TASK_SUCCESS:
      // FIX THE EXCHANGE OF TASK IN CARD
      let filterdData = state.data[action.payload.source.droppableId].filter(
        (task) => task._id !== action.payload.task._id
      )
      if(action.payload.source.droppableId === action.payload.destination.droppableId){
        filterdData.splice(action.payload.destination.index,0,action.payload.task)
        state.data = {
        ...state.data,
        [action.payload.source.droppableId]: filterdData,}
      }else{
        state.data = {
          ...state.data,
          [action.payload.source.droppableId]: filterdData
        }
        let destinationData = state.data[action.payload.destination.droppableId]
        if(destinationData && destinationData !== undefined){
          destinationData.splice(action.payload.destination.index,0,action.payload.task)
        }
        state.data = {
        ...state.data,
        [action.payload.destination.droppableId]: destinationData
          ?  destinationData : [action.payload.task],
      };
      }
      

      
      return state;

    case DELETE_CURRENT_TASK_SUCCESS:
      state = {
        ...state,
        allTask: [],
      };
      return state;
    case TASK_MESSAGE:
      return {
        ...state,
        message: action.message,
        loading: false,
      };
    default:
      return state;
  }
}

export default TaskReducer;
